import { createCancelToken } from '@apis/ServiceController';
import { message } from '@components/Message';
import SEO from '@components/SEO';
import IconArrow16Bold from '@icons/IconArrow16Bold';
import { feedbackService } from '@services/feedbackService';
import { convertToDate, isValidURL } from '@utils/common';
import constant from '@utils/constant';
import ContentLoading from '@views_admin/ContentLoading';
import { Col, Row, Tooltip } from 'antd';
import { navigate } from 'gatsby';
import React, { Fragment, memo, useEffect, useRef, useState } from 'react';
import _capitalize from 'lodash/capitalize';
import _lowerCase from 'lodash/lowerCase';
import IconDownload from '@icons/IconDownload';
import { downloadFileFromUrlString } from '@utils/fileUtils';
import { FeedbackStatus } from '@utils/enums/FeedbackStatus';
import IconCheckDone from '@icons/IconCheckDone';

const infoFields = [
   {
      label: 'Sender',
      name: '',
      valueFormatter: (_, data) =>
         data ? (
            <>
               {data.full_name} (
               <a
                  href={`mailto:${data.email}`}
                  className="feedback-email"
                  target="_blank"
                  rel="noreferrer"
               >
                  {data.email}
               </a>
               )
            </>
         ) : (
            ''
         ),
   },
   {
      label: 'Sent date',
      name: 'created_at',
      valueFormatter: (v) => (v ? convertToDate(v) : ''),
   },
   {
      label: 'Status',
      name: 'status',
      valueFormatter: (v) => (
         <span className={`feedback-stt ${v}`}>{_capitalize(_lowerCase(v))}</span>
      ),
   },
   {
      label: 'Category',
      name: 'category',
      valueFormatter: (v) => v?.name || '',
   },
   {
      label: 'Role',
      name: 'role',
      valueFormatter: (v) => _capitalize(_lowerCase(v)),
   },
   {
      label: 'Phone number',
      name: 'phone_number',
   },
   {
      label: 'Order ID',
      name: 'order_id',
   },
   {
      label: 'Product link',
      name: 'product_url',
      valueFormatter: (v) =>
         v && isValidURL(v) ? (
            <a
               href={v}
               className="feedback-product-url"
               title={v}
               target="_blank"
               rel="noreferrer"
            >
               {v}
            </a>
         ) : (
            v
         ),
   },
];

const FeedbackDetails = memo((props) => {
   const uid = props.id;
   const [loading, setLoading] = useState(true);
   const [submitting, setSubmitting] = useState(false);
   const [data, setData] = useState(null);
   const cancelToken = useRef();

   const loadData = async (uid) => {
      if (cancelToken.current) {
         cancelToken.current.cancel();
      }
      await setLoading(true);
      cancelToken.current = createCancelToken();
      const response = await feedbackService.getFeedbackDetails(
         uid,
         cancelToken.current?.token
      );
      if (response.isSuccess) {
         setData(response.data);
         setLoading(false);
      } else {
         message.error(response.message);
         navigate(constant.ROUTE_ADMIN_FEEDBACK);
      }
   };

   useEffect(() => {
      loadData(uid);
      return () => {
         if (cancelToken.current) {
            cancelToken.current.cancel();
         }
      };
   }, [uid]);

   const goBack = () => {
      navigate(constant.ROUTE_ADMIN_FEEDBACK);
   };

   const resolve = async () => {
      await setSubmitting(true);
      const response = await feedbackService.resolveFeedbacks([uid]);
      if (response.isSuccess) {
         loadData(uid);
      }
      setSubmitting(false);
   };

   return (
      <>
         <SEO title={'Events - Admin'} />
         <ContentLoading isLoading={loading} />
         <div className="admin-product-details">
            <div className="content-head admin-product-details__header">
               <div className="content-head__left">
                  <label className="title">
                     <i onClick={goBack}>
                        <IconArrow16Bold />
                     </i>
                     {data?.full_name && <span>Feedback from “{data.full_name}”</span>}
                  </label>
               </div>
               <div className="content-head__right admin-actions-group">
                  {data && (
                     <>
                        {data.status !== FeedbackStatus.RESOLVED ? (
                           <button
                              className="submit"
                              onClick={resolve}
                              disabled={submitting}
                           >
                              Resolve
                           </button>
                        ) : (
                           <div className="admin-feedback-details__resolved">
                              <IconCheckDone isActive={true} />
                              Resolved
                           </div>
                        )}
                     </>
                  )}
               </div>
            </div>
            <div className="content-body admin-feedback-details">
               <div className="admin-feedback-details__card">{data?.content || ''}</div>
               <div className="admin-feedback-details__card">
                  <div className="admin-feedback-details__card--header">Infomation</div>
                  <div className="admin-feedback-details__card--body">
                     <Row gutter={[10, 8]}>
                        {infoFields.map((field) => {
                           let value = data?.[field.name];
                           if (field.valueFormatter) {
                              value = field.valueFormatter(value, data);
                           }
                           return (
                              <Fragment key={field.label}>
                                 <Col lg={4} md={24} sm={24} xs={24}>
                                    <label className="field-title">{field.label}</label>
                                 </Col>
                                 <Col lg={20} md={24} sm={24} xs={24}>
                                    <span className="field-value">{value}</span>
                                 </Col>
                              </Fragment>
                           );
                        })}
                     </Row>
                  </div>
               </div>
               {data?.attachments?.length > 0 && (
                  <div className="admin-feedback-details__card">
                     <div className="admin-feedback-details__card--header">
                        Attachments
                     </div>
                     <div className="admin-feedback-details__card--body admin-feedback-details__attachments">
                        {data?.attachments?.map((file) => (
                           <div
                              key={file.uid}
                              className="admin-feedback-details__attachments--item"
                           >
                              <a
                                 href={file.url}
                                 title={file.name}
                                 target="_blank"
                                 rel="noreferrer"
                              >
                                 {file.name}
                              </a>
                              <Tooltip placement="bottom" title="Download" color="black">
                                 <button
                                    className="button-icon"
                                    onClick={() =>
                                       downloadFileFromUrlString(file.name, file.url)
                                    }
                                 >
                                    <IconDownload />
                                 </button>
                              </Tooltip>
                           </div>
                        ))}
                     </div>
                  </div>
               )}
            </div>
         </div>
      </>
   );
});

export default FeedbackDetails;
