import React from 'react';

const IconArrow16Bold = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0.292893 8.70706C-0.0976311 8.31654 -0.0976311 7.68337 0.292893 7.29285L6.29289 1.29285L7.70711 2.70706L3.41421 6.99995H16V8.99995H3.41421L7.70711 13.2928L6.29289 14.7071L0.292893 8.70706Z"
         fill="currentColor"
      />
   </svg>
);

export default IconArrow16Bold;